import React, { Component } from 'react';

class Settings extends Component {
  render() {
    return (
      <h1 data-testid="settings-title">Configurações</h1>
    );
  }
}

export default Settings;
